@use "sass:map";
@import '@webpixels/css/core/colors';

$min-contrast-ratio: 1.85;
$prefix: x-;

$white: #FFF;
$gray-50: #f8fafc;
$gray-100: #d9d9d948;
$gray-200: #d9d9d980;
$gray-300: #D9D9D9B3;
$gray-400: #94a3b8;
$gray-500: #64748b;
$gray-600: #475569;
$gray-700: #333333; // Used for text color
$gray-800: #1e293b; // Used for headings color h1 - h3
$gray-900: #0f172a; // Used for headings colors h3 - h6

// Theme colors
$primary: #0691F6; //#575EEE;
$secondary: #0BC484; //#0691F6;
$tertiary: #304FFE;
$success: $green;
$warning: $orange;
$danger: $red;

$light: #EEF4FA; //lighten($gray-100, 1%);
$dark: $gray-900;

$midnight-blue: #1C1E53;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// Need to call this $breakpoints and not $grid-breakpoints because there is a bug in webpixels sass library that overwrites it.
$breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1450px,
        jumbo: 1600px,
);
$grid-breakpoints: $breakpoints;

//@debug "grid breakpoint: #{$grid-breakpoints}";
@debug "$breakpoints custom keys #{map.keys($breakpoints)}" ;
@debug "$breakpoints custom values #{map.values($breakpoints)}" ;

// Grid containers

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1370px,
        jumbo: 1520px,
);

// Typography
$font-display: sans-serif;
$headings-font-family: $font-display;
$headings-font-weight: 600;
$headings-big-font-weight: 500;


$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;

$h1-font-size:                $font-size-base * 1.75 !default;
$h2-font-size:                $font-size-base * 1.625 !default;
$h3-font-size:                $font-size-base * 1.5 !default;
$h4-font-size:                $font-size-base * 1.375 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

$font-sizes: (
        1: $h1-font-size,
        2: $h2-font-size,
        3: $h3-font-size,
        4: $h4-font-size,
        5: $h5-font-size,
        6: $h6-font-size
) !default;


// Navbar
$navbar-nav-link-padding-x: 1rem;
$navbar-nav-link-padding-y: .375rem;

$navbar-vertical-nav-link-padding-x: 1rem;
$navbar-vertical-nav-link-padding-y: 0.625rem;
$navbar-vertical-open-width: 250px;

$navbar-vertical-nav-link-font-size: 1.25rem;


// Nav Tabs
$nav-tabs-border-width: 0;


// Table
$table-border-color: var(--#{$prefix}border-color);
$table-light-th-bg: var(--#{$prefix}body-bg);
$table-light-th-color: var(--#{$prefix}body-color);


// Border Radius
$border-radius: 0.8rem;
$border-radius-sm: 0.6rem;
$border-radius-lg: 1.2rem;
$border-radius-pill: 50rem;


// Buttons
$btn-border-radius: 10px;
$btn-border-radius-sm: 8px;
$btn-border-radius-lg: 12px;

$btn-padding-x: 0.875rem;
$btn-padding-y: 0.5rem;
$btn-font-size: 1rem;
$btn-font-weight: 500;

$btn-padding-x-sm: 0.75rem;
$btn-padding-y-sm: 0.25rem;
$btn-font-size-sm: 0.875rem;
$btn-font-weight-sm: 400;

$btn-padding-x-lg: 1.5rem;
$btn-padding-y-lg: 0.625rem;
$btn-font-size-lg: 1.25rem;
$btn-font-weight-lg: 600;

// Toggle
$form-check-input-checked-bg-color: $secondary;
$form-check-input-checked-border-color: $secondary;

// Dropdown Menu
$dropdown-min-width: auto;
$dropdown-font-size: 1rem;


// Cards
$card-border-color: none;
$card-box-shadow: none;

// Modal
$modal-header-padding: 2rem;
$modal-inner-padding: 2rem;
