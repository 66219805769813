// Braiv customisations

h1, .h1, h2, .h2, h3, .h3 {
  font-family: #{$headings-font-family};
  font-weight: #{$headings-big-font-weight};
  color: #{$midnight-blue};
}

h4, .h4, h5, .h5 {
  font-family: #{$headings-font-family};
  font-weight: #{$headings-big-font-weight};
  color: #{$gray-900};
}

h6, .h6 {
  font-family: #{$headings-font-family};
  font-weight: #{$headings-font-weight};
  color: #{$gray-900};
}

.text-midnight-blue {
  color: #{$midnight-blue};
}

.bg-grey-100 {
  background-color: #{$gray-100};
}

.app-panel {
  --x-bg-opacity: 1;
  background-color: #{$light};
}

.app-main-panel {
  height: calc(100vh - 4.0625rem);

  @supports (min-height: 100dvh) {
    height: calc(100dvh - 4.0625rem);
  }
}

.side-panel {
  --x-bg-opacity: 1;
  background-color: #eef5fe;
  gap: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.custom-offcanvas-panel {
  background-color: #EFF8FF;
}

.navbar-vertical .navbar-nav .nav-link {
  border-radius: var(--x-nav-link-border-radius);
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  background-color: var(--x-navbar-active-bg);
}

.disable-component {
  pointer-events: none;
  opacity: 0.5;
}

@include media-breakpoint-up(md) {
  .side-panel {
    min-width: 365px;
    max-width: 365px;
    width: 365px;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .select-custom-width {
    width: 162px;
  }
}

.card-img-top-video {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.btn-default {
  --x-btn-color: #{$gray-900};
  --x-btn-bg: #{$gray-50};
  --x-btn-border-color: #{$gray-200};

  --x-btn-hover-bg: #{$gray-100};
  --x-btn-hover-border-color: #{$gray-300};

  --x-btn-active-bg: #{$gray-100};
  --x-btn-active-border-color: #{$gray-500};
}

.logo-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.75rem;
  background-color: #{$gray-300};
  max-width: 360px;
  max-height: 60px;
  width: 100%;
}

.logo-preview-container img {
  object-fit: contain;
}

.poster-preview {
  border-width: 1rem;
  border-radius: 1rem;
  background-color: #{$gray-300};
  max-width: 800px;
  height: 180px;
}

.color-picker {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 1.75rem;
  padding: 0;
  margin: 0;
  border-width: 1px;
  border-Color: #{$gray-400};
}

.nav-link-sidebar {
  font-size: 1.15rem;
  font-weight: 300;
}

.login-image-panel {
  background-image: linear-gradient(to bottom, #8BC5FF, #EEF4FA);
}

.gradient-bg {
  background-image: linear-gradient(to bottom, #8BC5FF, #EEF4FA);
}

.custom-share-btn {
  background-color: #1C1E53;
  width: 30px;
  height: 30px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.custom-share-img {
  margin-bottom: 2px;
}

.dropdown #dropdown-video-card-options-button {
  width: 30px;
  height: 30px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 100%;
  padding: 0;
  --x-btn-bg: #{$gray-300};
  --x-btn-hover-bg: #{$white};
  --x-btn-active-bg: #{$white};
}

.dropdown #dropdown-video-card-flag-options-button {
  width: 30px;
  height: 30px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 100%;
  padding: 0;
  margin: 0;
  --x-btn-bg: #{$gray-800};
  --x-btn-hover-bg: #{$gray-500};
  --x-btn-active-bg: #{$gray-500};
  color: #{$white};
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}

.back-to-library a {
  color: black;
  font-weight: 300;
}

.disable-component {
  pointer-events: none;
  opacity: 0.5;
}

// player-style sidebar
#player-style-tab .nav-link {
  background-color: #{$gray-100};
  color: rgba(28, 30, 83, 0.4);
}

#player-style-tab .nav-link.active {
  background-color: white;
  color: black;
}

.tooltip [class$="inner"] {
  text-align: left;
  max-width: 220px;
  line-height: 17px;
}

// player-style edit screen
.primary-nav-tabs {
  border: none;
}

.primary-nav-tabs .nav-link {
  color: rgba(28, 30, 83, 0.4);
}

.primary-nav-tabs .nav-link.active {
  color: #{$gray-900};
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 3px;
  border-bottom-color: #{$primary};
}

.edit-sidebar-icon-text {
  font-size: 0.7rem;
}

.dropdown-menu li {
  text-align: center;
}

@include media-breakpoint-up(lg) {
  .main-panel {
    margin-right: 1rem;
  }

  .custom-title-panel {
    min-height: 6.338rem;
  }
}

.dropdown-toggle::after {
  content: none;
}

// Public page custom layout
.public-view-player.portrait-video {
  max-width: 40%;
  margin: 0 auto;
}

@include media-breakpoint-down(lg) {
  .public-view-width {
    max-width: 920px;
  }

  .public-view-container {
    width: 95%;
    height: 100%
  }

  .custom-onboard-pricing {
    max-width: 750px;
  }
}

@include media-breakpoint-down(sm) {
  .public-view-width {
    padding: 0;
  }

  .public-view-corners {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .public-view-container-padding {
    padding-right: 0;
    padding-left: 0;
  }

  .public-view-player.portrait-video {
    max-width: 80%;
  }

  .public-view-container {
    width: 100%;
    height: 100%;
  }

  #custom-task-tracker .dropdown-menu.show {
    min-width: 260px
  }
}

@include media-breakpoint-up(sm) {
  .public-view-corners {
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
  }

  .custom-task-tracker-text {
    white-space: nowrap;
  }
}

@include media-breakpoint-up(lg) {
  .public-view-container-padding {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .custom-onboard-pricing {
    max-width: 1350px;
  }
}

// Create video page customization
#urlInput::placeholder {
  color: rgba(170, 170, 170, 1);
}

#urlInputHelp {
  width: 90%;
}

.help-label {
  color: rgba(28, 30, 83, 0.4);
  line-height: 1.3;
  font-size: 0.85rem;
}

.drag-drop-area {
  border: 1px dashed #1C1E53;
}

.drag-drop-area:hover {
  cursor: pointer;
}

.suggested-format-text {
  font-size: 0.8rem;
}

.rti--container {
  --rti-main: #4AA4F8 !important;
  --rti-border: var(--x-border-width) solid var(--x-border-color);
  --rti-tag: rgba(217, 217, 217, 0.5) !important;
  //--rti-radius: var(--x-border-radius) !important;
  border-radius: var(--x-border-radius) !important;
  line-height: 1.2 !important;
  transition: box-shadow 0.15s ease-in-out !important;
}

.rti--input {
  color: black;
  background: white;
}

.rti--input::placeholder {
  color: rgba(170, 170, 170, 1);
  position: relative;
}

.rti--container:focus {
  box-shadow: 0px 4px 6px rgba(202, 228, 254);
}

.rti--tag button {
  color: black;
}

// Custom multi-select dropdown
.rmsc .dropdown-heading {
  font-weight: inherit;
}

.rmsc .dropdown-container {
  border-radius: var(--x-border-radius) !important;
  box-shadow: var(--x-box-shadow-inset) !important;
  border: var(--x-border-width) solid var(--x-border-color) !important;
}

.rmsc .gray {
  color: var(--x-body-color) !important
}

.rmsc .item-renderer input[type="checkbox"] {
  -webkit-appearance: none; /* Remove default styling on WebKit browsers */
  -moz-appearance: none; /* Remove default styling on Firefox */
  appearance: none; /* Remove default styling */
  background-color: white !important;
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 15px;
  height: 15px;
}

.rmsc .item-renderer input[type="checkbox"]:checked::before {
  content: "✓";
  display: block;
  color: black;
  text-align: center;
  line-height: 16px;
}

.rmsc .options {
  scrollbar-color: lightgrey transparent;
}

.rmsc .item-renderer {
  display: flex;
  justify-content: space-between;
}

.rmsc .item-renderer input[type="checkbox"] {
  order: 1;
}

.rmsc .item-renderer label {
  order: 0;
}

// Custom divider section for Signup page
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: .5em;
}

.separator:not(:empty)::after {
  margin-left: .5em;
}

.google-border {
  border: var(--x-border-width) solid var(--x-border-color);
  border-radius: var(--x-border-radius);
  box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08);
}

@include media-breakpoint-down(md) {
  .login-image-panel {
    background-image: none;
  }

  .custom-title-panel {
    min-height: 5.484rem;
  }
}

// Caption editor customizations
.caption-editor {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.timestamps-editor {
  display: inline-block;
  min-width: 100px;
  //border-bottom: '1px solid #000';
  padding-left: 3px;
}

.caption-editor.active > span {
  display: inline-block;
  padding: 1.5px 5px;
  border-radius: 5px;
  background-color: $gray-100;
}

// Task tracker customizations
.custom-emoji {
  font-size: 0.6rem;
  top: 60%;
}

.popover .popover-arrow {
  display: none;
}

.popover {
  max-width: 350px;
  overflow-y: auto;
}

@media (max-width: 575px) {
  .popover {
    max-width: 70%;
    width: 70%;
    max-height: 80vh;
  }
}

//Forces the window of import upload to be full height.
//This really only makes a difference on iOS where elements were being cut off
.import-upload-card-container {
  min-height: calc(100vh - 200px);
}

.no-border-header {
  border-bottom: none;
}

.no-border-footer {
  border-top: none;
}

.custom-modal .modal-dialog {
  max-width: 700px;
}

.hover-shadow:hover {
  background: rgba(217, 217, 217, 0.2823529412);
  border-radius: 0.6rem;
}

.custom-table-icon-font-size {
  font-size: 1.5rem;
}

.fixed-min-width-button {
  min-width: 157px;
}

.custom-modal-voices {
  max-height: 300px;
  scrollbar-color: rgba(217, 217, 217, 0.7019607843) white;
}

.custom-multi-select-dropdown-button {
  --x-btn-bg: none;
  --x-btn-hover-bg: rgba(255, 255, 255);
  --x-btn-active-bg: rgba(255, 255, 255);
  --x-border-radius: 0.2rem;
}

.custom-multi-select-dropdown-button[aria-expanded="true"] {
  box-shadow: 0 0 0 0.25rem rgba(6, 145, 246, 0.2); /* Custom blue shadow */
}

.custom-multi-select-dropdown {
  max-height: 270px;
  scrollbar-color: rgba(217, 217, 217, 0.7019607843) white;
}

.form-check-input:checked {
  background-color: #{$primary};
  border-color: #{$primary};
}

.custom-multi-select-dropdown .form-check-input {
  border: var(--x-border-width) solid grey;
}

.custom-bg-color {
  background: #ff9b00;
  color: white;
}

.custom-bg-color:hover {
  background: #{$gray-300};
  color: black;
}

.status-active-button {
  background-color: #ADEED8;
  border-color: #ADEED8;
  color: black;
}

.status-inactive-button {
  background-color: #f5b8b8;
  border-color: #f5b8b8;
  color: black;
}

.custom-modal-credits .modal-dialog {
  max-width: 1400px;
}

.custom-modal-subscription .modal-dialog {
  max-width: 1400px;
}

//.video-tile {
//  transition: box-shadow 0.2s ease, transform 0.2s ease;
//}

.video-tile:hover {
  box-shadow: 0 0 0.5rem rgba(6, 145, 246, 0.3);
}

.video-tile:active {
  box-shadow: 0 0 0.1rem rgba(6, 145, 246, 0.1);
  //transform: translateY(2px);
}

.video-card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.video-tile .loading-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10;
}

.video-tile .blur-background {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  filter: blur(5px);
  z-index: 5;
}

.card-img-top-video {
  transition: filter 0.3s ease; /* Smooth transition for blur effect */
}


.bg-dark-duration {
  background-color: #1C1E53;
  opacity: 0.7;
}

.custom-button-text {
  --x-btn-color: black;
}

.custom-purple-border {
  border: 1px solid #575EEE;
}

.custom-indigo-button {
  background-color: #575EEE;
  color: white;
  border: 1px solid #575EEE;
  --x-btn-hover-bg: #4950DF;
  --x-btn-hover-border-color: #4950DF;
  --x-btn-disabled-color: #FFF;
  --x-btn-disabled-bg: #575EEE;
  --x-btn-disabled-border-color: #575EEE;
}

.custom-indigo-button {
  background-color: #575EEE;
  color: white;
  border: 1px solid #575EEE;
  --x-btn-hover-bg: #4950DF;
  --x-btn-hover-border-color: #4950DF;
  --x-btn-disabled-color: #FFF;
  --x-btn-disabled-bg: #575EEE;
  --x-btn-disabled-border-color: #575EEE;
}

.custom-form-check .form-check-label {
  font-size: 1rem;
}

.get-started {
  max-width: 500px;
}


.table-wrap-text {
  word-break: break-word !important;
  white-space: normal !important;
  min-width: 300px;
}

// Custom CSS for onboarding setup (branding)
.video-container {
  box-sizing: border-box;
}

.player-container {
  position: relative;
  width: 100%;
  max-width: 720px;
  height: auto;
}

.section-container {
  max-width: 720px;
}

.fixed-width-image {
  width: 720px;
  box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.4)
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.setup-branding-logo {
  width: 5em;
  position: absolute;
  top: 4%;
  left: 7%;
  height: auto;
  z-index: 10;
}

.all-arrows {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.caption-text {
  position: absolute;
  bottom: 7%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  font-size: 1.3rem;
}

@media (max-width: map-get($breakpoints, lg)) {
  .fixed-width-image, .player-container, .section-container {
    width: 640px;
  }

  .setup-branding-logo {
    position: absolute;
    top: 4%;
    left: 2%;
    height: auto;
    z-index: 10;
  }

  .section-container {
    height: 470px;
  }

  .sub-heading-container {
    height: 180px;
  }

  .caption-text {
    font-size: 1.1rem;
  }

  .custom-title-panel {
    min-height: 5.484rem;
  }
}

@media (max-width: map-get($breakpoints, md)) {
  .fixed-width-image, .player-container, .section-container {
    width: 480px;
  }

  .all-arrows {
    width: 90%;
  }

  .play-icon {
    width: 60px;
    height: 60px;
  }

  .section-container {
    height: 380px;
  }

  .sub-heading-container {
    height: 180px;
  }

  .caption-text {
    font-size: 1rem;
  }
}

@media (max-width: map-get($breakpoints, sm)) {
  .fixed-width-image, .player-container, .section-container {
    width: 360px;
  }

  .play-icon {
    width: 40px;
    height: 40px;
  }

  .all-arrows {
    width: 80%;
  }

  .setup-branding-logo {
    top: 2%;
    left: 3%;
    width: 3rem;
    height: auto;
  }

  .section-container {
    height: 260px;
  }

  .sub-heading-container {
    height: 130px;
  }

  .sub-heading-container p {
    font-size: 0.8rem;
  }

  .sub-heading-container h1 {
    font-size: 1.3rem;
  }

  .caption-text {
    font-size: 0.8rem;
  }
}

.import-form-control:focus {
  border-color: var(--x-secondary);
  box-shadow: 0 1px 2px rgba(50, 50, 71, 0.08), 0 0 0 2px rgba(var(--x-secondary-rgb), 0.25)
}

.import-form-control {
  border-color: var(--x-secondary);
}

.options-dropdown {
  z-index: 1050;
}

.sticky-title {
  z-index: 50;
}

.form-control.folder-name {
  font-size: 0.875rem;
  outline: none;
  box-shadow: none;
  line-height: inherit;
  font-weight: 500;
  border: none;
  border-bottom: 1px solid  #{$primary};
}

.progress-tracker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 800px;
  margin: 40px auto;
  padding: 20px 0;
}

.progress-tracker::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10%;
  width: 80%;
  height: 4px;
  background-color: #ddd;
  transform: translateY(-50%);
  z-index: 0;
}

.step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.step-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  border: 3px solid #ddd;
  background-color: white;
  color: #777;
  transition: all 0.3s ease;
}

.step.active .step-circle {
  border-color: #ff9800;
  background-color: #ff9800;
  color: white;
}

.step.completed .step-circle {
  border-color: #4caf50;
  background-color: #4caf50;
  color: white;
}

.step-title {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.step-status {
  font-size: 12px;
  color: #777;
}

/* Connect active steps with a colored line */
.progress-tracker .step.completed ~ .step::before {
  background-color: #4caf50 !important;
}

.progress-tracker .step.active ~ .step::before {
  background-color: #ff9800 !important;
}

/* Individual step lines */
.step::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -50%;
  width: 100%;
  height: 4px;
  background-color: #ddd;
  z-index: -1;
}

.step:first-child::before {
  display: none;
}

.btn-circle {
  width: 60px;
  height: 60px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tracker-circle-orange {
  --x-btn-disabled-bg: #FFAB57;
  --x-btn-disabled-border-color: #FFAB57;
  --x-btn-disabled-opacity: 1;
}

.tracker-circle-orange-border {
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #FFAB57;
  --x-border-color: #FFAB57;
  --x-btn-disabled-color: #FFAB57;
  --x-btn-disabled-opacity: 1;
}

.tracker-circle-indigo {
  --x-btn-disabled-bg: #575EEE;
  --x-btn-disabled-border-color: #575EEE;
  --x-btn-disabled-opacity: 1;
}

.tracker-circle-indigo-border {
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #575EEE;
  --x-border-color: #575EEE;
  --x-btn-disabled-color: #575EEE;
  --x-btn-disabled-opacity: 1;
}

.tracker-circle-blue {
  --x-btn-disabled-bg: #{$primary};
  --x-btn-disabled-border-color: #{$primary};
  --x-btn-disabled-opacity: 1;
}

.tracker-circle-blue-border {
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #{$primary};
  --x-border-color: #{$primary};
  --x-btn-disabled-color: #{$primary};
  --x-btn-disabled-opacity: 1;
}

.tracker-circle-green {
  --x-btn-disabled-bg: #{$secondary};
  --x-btn-disabled-border-color: #{$secondary};
  --x-btn-disabled-opacity: 1;
}

.tracker-circle-green-border {
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #{$secondary};
  --x-border-color: #{$secondary};
  --x-btn-disabled-color: #{$secondary};
  --x-btn-disabled-opacity: 1;
}

.tracker-line {
  width: 100px;
  height: 4px;
  margin-bottom: 4.5rem;
}

.tracker-line-one {
  background: linear-gradient(to right, #575EEE, #2280F3);
}

.tracker-line-two {
  background: linear-gradient(to right, #2280F3, #08AABD);
}

.tracker-line-three {
  background: linear-gradient(to right, #08AABD, #0BC484);
}

.tracker-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .tracker-container {
    flex-direction: column;
  }

  .tracker-line {
    width: 3px; /* Vertical line */
    height: 70px; /* Height between steps */
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
  }

  .step-container {
    transition: opacity 0.4s ease, max-height 0.6s ease, margin 0.4s ease;
    max-height: 225px;
    opacity: 1;
    overflow: hidden;
    flex-direction: column;
  }

  .step-container.animate, .custom-tracker-line-animation {
    max-height: 0px;
    opacity: 0;
    margin-bottom: 0px;
    pointer-events: none;
  }
}

.custom-modal-subscription .modal-header, .custom-modal-credits .modal-header {
  position: relative;
}

.custom-modal-subscription .modal-header .btn-close, .custom-modal-credits .modal-header .btn-close {
  order: 3;
}

@media (max-width: 767px) {
  .custom-modal-subscription .modal-header .btn-close, .custom-modal-credits .modal-header .btn-close {
    order: 0; /* Remove from the normal flow */
  }
}

.flag-round-container {
  width: 1.85rem;
  height: 1.85rem;
  border-radius: 50%;
  overflow: hidden;
}

.radio-hover-effect {
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.radio-hover-effect:hover {
  border-color: #90caf9;
  box-shadow: 0 0 8px rgba(144, 202, 249, 0.5);
}
