.dvh-100 {
  height: 100vh;

  @supports (height: 100dvh) {
    height: 100dvh;
  }
}

.min-dvh-100 {
  min-height: 100vh;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }
}

@include media-breakpoint-up(sm) {
  .dvh-sm-100 {
    height: 100vh;

    @supports (height: 100dvh) {
      height: 100dvh;
    }
  }

  .min-dvh-sm-100 {
    min-height: 100vh;

    @supports (min-height: 100dvh) {
      min-height: 100dvh;
    }
  }
}

@include media-breakpoint-up(md) {
  .dvh-md-100 {
    height: 100vh;

    @supports (height: 100dvh) {
      height: 100dvh;
    }
  }

  .min-dvh-md-100 {
    min-height: 100vh;

    @supports (min-height: 100dvh) {
      min-height: 100dvh;
    }
  }
}

@include media-breakpoint-up(lg) {
  .dvh-lg-100 {
    height: 100vh;

    @supports (height: 100dvh) {
      height: 100dvh;
    }
  }

  .min-dvh-lg-100 {
    min-height: 100vh;

    @supports (min-height: 100dvh) {
      min-height: 100dvh;
    }
  }
}

@include media-breakpoint-up(xl) {
  .dvh-xl-100 {
    height: 100vh;

    @supports (height: 100dvh) {
      height: 100dvh;
    }
  }

  .min-dvh-xl-100 {
    min-height: 100vh;

    @supports (min-height: 100dvh) {
      min-height: 100dvh;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .dvh-xxl-100 {
    height: 100vh;

    @supports (height: 100dvh) {
      height: 100dvh;
    }
  }

  .min-dvh-xxl-100 {
    min-height: 100vh;

    @supports (min-height: 100dvh) {
      min-height: 100dvh;
    }
  }
}